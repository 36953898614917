import React from "react";
import "../Home/home.css";

export default function GeneralOrder() {
  const supplierData = [
    { heading: "Govt Contractor", image: "/assets/images/Vector.png" },
    { heading: "Vehicles Retail Service", image: "/assets/images/Vector.png" },
    {
      heading: "Printing & Publishing Services",
      image: "/assets/images/Vector.png",
    },
    { heading: "Consultancy", image: "/assets/images/Vector.png" },
    { heading: "Trainings", image: "/assets/images/Vector.png" },
    { heading: "Architecture", image: "/assets/images/Vector.png" },
    {
      heading: "Institutional Development",
      image: "/assets/images/Vector.png",
    },
    { heading: "Education", image: "/assets/images/Vector.png" },
    { heading: "Strategic Planning", image: "/assets/images/Vector.png" },
    { heading: "Engineering", image: "/assets/images/Vector.png" },
    { heading: "Marketing", image: "/assets/images/Vector.png" },
    { heading: "Construction", image: "/assets/images/Vector.png" },
    { heading: "Import & Export", image: "/assets/images/Vector.png" },
    { heading: "Social Services", image: "/assets/images/Vector.png" },
    { heading: "Manufacturing", image: "/assets/images/Vector.png" },
    { heading: "Garments", image: "/assets/images/Vector.png" },
    { heading: "Handicraft", image: "/assets/images/Vector.png" },
    {
      heading: "Educational and Vocational Services",
      image: "/assets/images/Vector.png",
    },
    { heading: "Banquet Hall", image: "/assets/images/Vector.png" },
    { heading: "Hotel & Restaurants", image: "/assets/images/Vector.png" },
    { heading: "Clearing Agent", image: "/assets/images/Vector.png" },
    {
      heading: "Caterings & Outdoor Events",
      image: "/assets/images/Vector.png",
    },
  ];
  return (
    <div className="container">
      <div className="deals-detail-section animation-section">
        <div className="row">
          <h1>Deals in General order suppliers</h1>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="row">
              {supplierData.map((item, index) => (
                <div className="col-lg-6 col-md-12 xol-12 mt-4">
                  <div className=" supplier-detail">
                    <img src={item.image} alt="" />

                    <h6>{item.heading}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12  ">
            <div className="row">
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="gallery">
                  <div
                   
                    style={{
                      backgroundImage: "url(/assets/images/DealDetail/car.jpg)",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      height: "200px",
                      width: "100%",
                    }}
                  ></div>
                  <h1 className="fs-3 my-3 text-center">Let's go with it</h1>

                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                       className="mb-3"
                        style={{
                          backgroundImage:
                            "url(/assets/images/DealDetail/marketing.jpg)",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          height: "335px",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div
                        className="mb-3"
                        style={{
                          backgroundImage:
                            "url(/assets/images/DealDetail/marketing.jpg)",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          height: "100px",
                          width: "100%",
                        }}
                      ></div>
                      <div
                        className="mb-3"
                        style={{
                          backgroundImage:
                            "url(/assets/images/DealDetail/marketing.jpg)",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          height: "100px",
                          width: "100%",
                        }}
                      ></div>
                      <div
                        style={{
                          backgroundImage:
                            "url(/assets/images/DealDetail/marketing.jpg)",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                          height: "100px",
                          width: "100%",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <img src="/assets/images/DealDetail/img1.png" alt="" className="mb-4 w-100"/>
                <img src="/assets/images/DealDetail/img2.jpg" alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          opacity: "2px",
          border: "1px solid grey",
          marginTop: "120px",
        }}
      />
    </div>
  );
}
