import React from "react";
import './footer.css'
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedin } from "react-icons/fa";

function Footer() {
    return (
        <>
            <div className="footer-detail ">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-9 col-sm-12">
                            <div className="company-footer-detail ">
                                <h5 className="">Al-Aziz Traders</h5>
                                <p className="w-75">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer lobortis.</p>

                                <div className="social-icons ">
                                    <span>
                                        <FaFacebookF />
                                    </span>
                                    <span>
                                        <FaTwitter />
                                    </span>
                                    <span>
                                        <FaYoutube />
                                    </span>
                                    <span>
                                        <FaLinkedin />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-9 col-sm-12">
                            <div className="other-links">
                                <h3>Useful Links</h3>
                                <ul className="p-0">
                                    <li>
                                        Blog
                                    </li>
                                    <li>
                                       Pricing
                                    </li>
                                    <li>
                                        About
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-9 col-sm-12">
                            <div className="footer-terms">
                                <h3>Terms</h3>
                                <ul className="p-0">
                                    <li>
                                        TOS
                                    </li>
                                    <li>
                                        Privacy Policy
                                    </li>
                                    <li>
                                        Refund
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-9 col-sm-12">
                            <div className="footer-help-section">
                                <h3 className="text-white mt-3">Support & Help</h3>
                                <ul className="p-0">
                                    <li>
                                        Open Support Ticket
                                    </li>
                                    <li>
                                        Terms of Use
                                    </li>
                                    <li>
                                        About
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <hr className="mt-5" style={{opacity:"2px",border:"1px solid grey"}} />
                        
                        <div className="copyright-section">
                            <p>© Copyright 2023 Al Aziz Traders. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Footer;