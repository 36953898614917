import React from "react";
import "../Home/home.css";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const BlogCarousel = () => {
  let img1 = "/assets/images/blog/blog-01.jpg";
  let img3 = "/assets/images/blog/blog-03.jpg";
  let img4 = "/assets/images/blog/blog-02.jpg";
  let img2 = "/assets/images/blog/blog-04.jpg";

  let slide1Title = "Best UI components for modern websites";
  let slide3Title = "9 simple way to improve your design skills";
  let slide4Title = "Tips to weekly improve your coding speed";
  let slide2Title = "Cheapest Umrah  -  PKR  25,499";

  let subText1 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet dictum neque, laoreet dolor.";
  let subText3 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet dictum neque, laoreet dolor.";
  let subText4 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet dictum neque, laoreet dolor.";
  let subText2 =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sit amet dictum neque, laoreet dolor.";

  const BlogData = [
    {
      image: img1,
      title: slide1Title,
      dec: subText1,
      profileImage: "/assets/images/blog/author-01.png",
      profileName: "By Samuyl Joshi",
      profileDesc: "Graphic Designer",
    },
    {
      image: img2,
      title: slide2Title,
      dec: subText2,
      profileImage: "/assets/images/blog/author-02.png",
      profileName: "By Musharof Chy",
      profileDesc: "Content Writer",
    },
    {
      image: img3,
      title: slide3Title,
      dec: subText3,
      profileImage: "/assets/images/blog/author-03.png",
      profileName: "By Lethium Deo ",
      profileDesc: "Graphic Designer",
    },
    {
      image: img4,
      title: slide4Title,
      dec: subText4,
      profileImage: "/assets/images/blog/author-01.png",
      profileName: "By Samuyl Joshi",
      profileDesc: "Graphic Designer",
    },
  ];
  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={2000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 3,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
            partialVisibilityGutter: 30,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
        autoPlay={true}
        
      >
        {BlogData.length > 0 &&
          BlogData.map((item, index) => {
            return (
              <div key={index}>
                <div className="container p-0">
                  <div className="work1 ml-2 mr-2">
                    <div className="image-box">
                      <div className="name-desc hover-1 image-box rounded">
                        <div className="img align-items-center justify-content-center rounded">
                          <img
                            alt=""
                            src={item.image}
                            style={{ width: "400px", height:'400px' }}
                          />

                          <a
                            href="#"
                            className="icon d-flex align-items-center justify-content-center"
                          >
                            <span className="ion-ios-search"></span>
                          </a>
                        </div>
                        <div className="hover-1-content ml-5 ">
                          <div className="title float-left">{item.title}</div>

                          <div className="sub-txt-desc float-left pl-2">
                            <p>{item.dec}</p>
                          </div>
                          <hr
                            style={{
                              opacity: "2px",
                              border: "1px solid grey",
                              width: "340px",
                            }}
                          />

                          <div className="profile-imgs d-flex">
                            <img src={item.profileImage} alt="" />
                            <div className="profile-person-detail">
                              <p>{item.profileName}</p>
                              <span>{item.profileDesc}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </Carousel>
    </>
  );
};

export default BlogCarousel;
