import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MainCarousal() {
  // Sample data for carousel items
  const carouselData = [
    {
      title: "Revolutionize, Co-create, Automate. Its Your Al-AZIZ",
      content:
        "Al-AZIZ, were not just a trading company; we are a dynamic force for change and innovation. We are here to revolutionize the way you approach trading and supply orders, and we do it through a powerful trifecta of principles",
    },
    {
      title: "Revolutionize, Co-create, Automate. Its Your Al-AZIZ ",
      content:
        "Al-AZIZ, were not just a trading company; we are a dynamic force for change and innovation. We are here to revolutionize the way you approach trading and supply orders, and we do it through a powerful trifecta of principles",
    },
    {
      title: "Revolutionize, Co-create, Automate. Its Your Al-AZIZ",
      content:
        "Al-AZIZ, were not just a trading company; we are a dynamic force for change and innovation. We are here to revolutionize the way you approach trading and supply orders, and we do it through a powerful trifecta of principles",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000, // Set the autoplay speed in milliseconds
  };
  return (
    <div>
      <Slider {...settings} className="slider">
        {carouselData.map((data, i) => (
          <div className="slide">
            <h2 className="text-center">{data.title}</h2>
            <p>
              {data.content}
            </p>
          </div>
        ))}

        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
}

export default MainCarousal;
