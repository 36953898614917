import React from 'react'
import '../Home/home.css'

export default function Objectives() {

    const Objective = [
        {
            heading: "Customer Satisfaction",
            detail: "To consistently meet and exceed customer expectations by providing high-quality products and exceptional service, ensuring utmost satisfaction.",
        },
        {
            heading: "Diversification of Product Range",
            detail: "Cultivate strong and sustainable relationships with reliable suppliers to ensure a steady and dependable source of quality goods.",
        },
        {
            heading: "Efficient Supply Chain Management",
            detail: "Explore and enter new markets, both geographically and in terms of different industries, to grow the customer base and increase market share.",
        },
        {
            heading: "Embrace Technological Advancements",
            detail: "Incorporate technological advancements and modern tools to streamline operations, enhance productivity, and improve customer experiences.",
        },
        {
            heading: "Environmental and Social Responsibility",
            detail: "Promote sustainability by adopting eco-friendly practices and adhering to ethical standards in sourcing and delivering products.",
        },
        {
            heading: "Employee Development",
            detail: "Invest in the training and development of employees, fostering a culture of growth, skill enhancement, and job satisfaction.",
        },
    ]
    return (
        <div>
            <section className="object-section-detail ">
                <div className="container">
                    <div className="object-section">
                        <h1 className="">Objective & Gooals</h1>
                        <div>
                            <div className="row mt-4">

                                {Objective.map((data,i)=>(
                                    <div className="col-lg-4 col-md-8 col-sm-12 animation-section">
                                    <div class="card">
                                        <div className="menu-icon-image">
                                            <img
                                                src="/assets/images/Group 39920.png"
                                                class="card-img-top"
                                                alt="..."
                                            />
                                        </div>
                                        <div class="card-body mt-4">
                                            <h5 class="card-title">{data.heading}</h5>
                                            <p class="card-text mt-4">
                                            {data.detail}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                ))}
                                
                              
                            </div>
                      
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
