import React, { useEffect, useState } from "react";
import "./home.css";
import YouTube from "react-youtube";
import BlogCarousel from "../BlogCarousel/Blogcarousel";
import Footer from "../Footer/footer";
// import TextCarousel from "../HomeTextCarousel/textcarousel";
import Carousel from "react-bootstrap/Carousel";
import TextCarousel from "../HomeTextCarousel/textcarousel";
import Navbar from "../Navbar/Navbar";
import ScrollToTop from "../ScrollToTop";
import MainCarousal from "../MainCarousal/MainCarousal";
import Objectives from "../Objectives/Objectives";
import GeneralOrder from "../GeneralOrder/GeneralOrder";
import Reviews from "../Reviews/Reviews";
import Pricing from "../Pricing/Pricing";



function Home() {
  useEffect(() => {
    const sections = document.querySelectorAll(".animation-section");

    const handleScroll = () => {
      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight * 0.75) {
          section.classList.add("appear");
        }
      });
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check in case some sections are already in the viewport on load
    handleScroll();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [videoId, setVideoId] = useState("YOUR_VIDEO_ID");


  return (
    <div className="">
      <section className="top-navbar pb-5 mb-4">
        <Navbar />
      </section>

      <section className="">
        <div className="">
          <MainCarousal />
        </div>
      </section>

      <Objectives />

      <section className="help-section-center ">
        <div className="container">
          <div className="help-section animation-section">
            <h1>We are ready to help</h1>
            <p>
              There are many variations of passages of Lorem Ipsum available but
              the majority have suffered alteration in some form.
            </p>
          </div>
          <div className="row">
            <div className="col-12 utube-video-section p-4">
              <YouTube
                id={videoId}
                width="800"
                height="315"
                controls={true}
                autoplay={true}
              />
            </div>
          </div>
        </div>

        <div className="company-partners-section ">
          <div className="container">
            <div className="row justify-content-center align-items-center patner-img">
              <div className="col-lg-2 col-md-6 col-sm-12">
                <img src="/assets/images/brands/uideck.svg" alt="" />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <img src="/assets/images/brands/tailgrids.svg" alt="" />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <img src="/assets/images/brands/lineicons.svg" alt="" />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <img src="/assets/images/brands/ayroui.svg" alt="" />
              </div>
              <div className="col-lg-2 col-md-6 col-sm-12">
                <img src="/assets/images/brands/plainadmin.svg" alt="" />
              </div>
            </div>
          </div>
        </div>


        

        <GeneralOrder />

        <div className="container">
          <div className="row animation-section">
            <div className="col-lg-6 col-md-8 col-sm-12 mt-5 ">
              <div className="mt-5 pt-5">
                <img
                  className="bug-fixer-section-img"
                  src="/assets/images/about/about-image-2-dark.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-12 mt-5 ">
              <div className="Premier-support-section">
                <div className="mt-5 pt-5">
                  <h1>Bug free code</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div className="Premier-support-section">
                <div className="mt-5 pt-5">
                  <h1>Premier support</h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt.
                  </p>
                </div>
              </div>
              <div className="Premier-support-section">
                <div className="mt-5 pt-5">
                  <h1>React Js</h1>
                  <p>
                    Lorem ipsum dolor sit amet, sed do eiusmod tempor incididunt
                    consectetur adipiscing elit setim.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Reviews/>

    <Pricing/>
      <section className="blog-detail-section">
        <div className="container">
          <div className="blog-detail-heading">
            <div className="pb-5">
              <h1>Our Latest Blogs</h1>
              <p className="mt-5">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p>
            </div>
            <div></div>
          </div>
          <BlogCarousel/>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-12 col-sm-12 mt-5">
              <div className="contact-form pb-2">
                <div className="contact-form-heading">
                  <h1>Need Help? Open a Ticket</h1>
                  <p>Our support team will get back to you ASAP via email.</p>
                </div>
                <div className="row p-4">
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <label>Your Name</label>
                    <div>
                      <input type="text" placeholder="Enter Your Name" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-8 col-sm-12">
                    <label>Your Email</label>
                    <div>
                      <input type="text" placeholder="Enter Your Email" />
                    </div>
                  </div>

                  <div className="col-lg-12 mt-5">
                    <label>Your Message</label>
                    <div>
                      <textarea
                        name="message"
                        rows={5}
                        placeholder="Enter your Message"
                        className="border-stroke dark:text-body-color-dark dark:shadow-two w-full resize-none rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 mt-4">
                    <div className="submit-btn">
                      <button>Submit Ticket</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-8 col-sm-12 mt-5">
              <div className="contact-form">
                <iframe
                  style={{
                    height: "600px",
                    borderRadius: "10px",
                    padding: "15px",
                    width: "300px",
                  }}
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Bahawalpur&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="footer-section"></section>

      <ScrollToTop />

      <Footer />
    </div>
  );
}
export default Home;
