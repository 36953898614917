import React, { useEffect, useState } from "react";
import '../Home/home.css';
import { RxHamburgerMenu } from "react-icons/rx";
function Navbar() {
    const [isScrolled, setIsScrolled] = useState(false);

    const [showSidebar, setSidebar] = useState(false);

    const [colorChange, setColorchange] = useState(false);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };
    window.addEventListener("scroll", changeNavbarColor);

    const showSideBarFunction = () => {
        if (showSidebar == false) {
            setSidebar(true);
        } else {
            setSidebar(false);
        }
    };

    return (
        <>
            <nav className={
                colorChange
                    ? "navbar navbar-expand-lg navbar-light fixed-top navbar1 colorChange"
                    : "navbar navbar-expand-lg navbar-light fixed-top "
            }>
                <div class="container">
                    <img className="logo-wrapper" src="/assets/images/logo.png"/>
                    {/* <a href="#" class="navbar-brand text-white fw-bold pl-5">
                        Al Aziz
                    </a> */}
                    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class=""><RxHamburgerMenu className="text-white"/>
                        </span>
                    </button>
                    <div class="collapse navbar-collapse justify-content-end contact-detail " id="navbarCollapse">
                        <div className="d-flex">
                            <i class="fas fa-clock" style={{ color: '#FFF' }}></i>
                            <div className="mt-3">
                                <a> OPENING TIME</a>
                                <p class="">Monday to Friday 9:00 to 18:00</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <i class="fas fa-phone-alt" style={{ color: '#FFF' }}></i>
                            <div className="mt-3">
                                <a>Call Us, We are Available</a>
                                <p class="">923008386186</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <i class="fas fa-envelope" style={{ color: '#FFF' }}></i>
                            <div className="mt-3">
                                <a> EMAIL US</a>
                                <p class="">alaziztraderbwp@gmail.com</p>
                            </div>
                        </div>
                        {/* <ul class="navbar-nav">
                            <li class="nav-item">
                                <a href="#" class="nav-link active me-4 mb-0 fw-bold">
                                    <i class="fas fa-clock"></i> OPENING TIME
                                </a>
                                <p class="mb-4">Monday to Friday 9:00 to 18:00</p>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link me-4 fw-bold mb-0 ">
                                    <i class="fas fa-phone-alt"></i> Call Us, We are Available
                                </a>
                                <p class="ml-5 mb-4">923008386186</p>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link me-4 mb-0 fw-bold">
                                    <i class="fas fa-envelope"></i> EMAIL US
                                </a>
                                <p class="mb-4">alaziztraderbwp@gmail.com</p>
                            </li>
                        </ul> */}


                    </div>
                </div>
            </nav>



        </>
    )
}
export default Navbar;