import React from "react";
import "../Home/home.css";

function Reviews() {
  const reviews = [
    {
      name: "Musharof Chy",
      desc: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.",
      founder: "TailGrids",
      img: "/assets/images/testimonials/auth-01.png",
      stars: "3",
    },
    {
      name: "Musharof Chy",
      desc: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.",
      founder: "TailGrids",
      img: "/assets/images/testimonials/auth-01.png",
      stars: "4",
    },
    {
      name: "Musharof Chy",
      desc: "“Our members are so impressed. It's intuitive. It's clean. It's distraction free. If you're building a community.",
      founder: "TailGrids",
      img: "/assets/images/testimonials/auth-01.png",
      stars: "2",
    },
  ];

  const renderStars = (numStars) => {
    const stars = [];
    for (let i = 0; i < numStars; i++) {
      stars.push(<span className="fa fa-star checked mr-1" key={i}></span>);
    }
    return stars;
  };

  return (
    <section className="client-review-section ">
      <div className="container animation-section">
        <div className="client-review-heading">
          <div>
            <h1 className="mt-3">What Our Users Says</h1>
            <p className="mt-4">
              There are many variations of passages of Lorem Ipsum available but
              the majority have suffered alteration in some form.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          {reviews.map((data, i) => (
            <div className="col-lg-4 col-md-8 col-sm-12" key={i}>
              <div className="customer-review-section">
                <div className="rating-star">
                  {renderStars(data.stars)}
                  <p className="mt-3">
                   {data.desc}
                  </p>
                  <hr style={{ opacity: "2px", border: "1px solid grey" }} />
                </div>
                <div className="customer-profile">
                  <div>
                    <img src={data.img} alt="" />
                  </div>
                  <div>
                    <h6>{data.name}</h6>
                    <p>Founder @{data.founder}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        
        </div>
      </div>
    </section>
  );
}

export default Reviews;
