import React from "react";
import "../Home/home.css";

function Pricing() {
  const pricingData = [
    {
      price: "40",
      packageName: "Lite",
      detail: "Lorem ipsum dolor sit amet adiscing elit Mauris egestas enim.",
      
    },
    {
      price: "40",
      packageName: "Lite",
      detail: "Lorem ipsum dolor sit amet adiscing elit Mauris egestas enim.",
    },
    {
      price: "40",
      packageName: "Lite",
      detail: "Lorem ipsum dolor sit amet adiscing elit Mauris egestas enim.",
    },
  ];
  return (
    <section className="pricing-detail-section ">
      <div className="container animation-section">
        <div className="pricing-detail-heading">
          <div>
            <h1 className="mt-3">Simple and Affordable Pricing</h1>
            <p className="mt-4">
              There are many variations of passages of Lorem Ipsum available but
              the majority have suffered alteration in some form.
            </p>
          </div>
        </div>

        <div className="row mt-5">
          {pricingData.map((data, i) => (
            <div className="col-lg-4 col-md-8 col-sm-12">
              <div className="pricing-detail">
                <div className="offer-price">
                  <h1>
                    ${data.price}/<span>mo</span>
                  </h1>
                  <p>{data.packageName}</p>
                </div>
                <div className="offer-pricing-para">
                  <p>
                  {data.detail}
                  </p>
                </div>
                <hr style={{ opacity: "2px", border: "1px solid grey" }} />

                <div className=" offering-detail ">
                  <div className="d-flex ">
                    <img src="/assets/images/Vector.png" alt="" />
                    <p>All UI Components</p>
                  </div>
                  <div className="d-flex ">
                    <img src="/assets/images/Vector.png" alt="" />
                    <p>Use with Unlimited Projects</p>
                  </div>
                  <div className="d-flex ">
                    <img src="/assets/images/Vector.png" alt="" />
                    <p>Commercial Use</p>
                  </div>
                  <div>
                    <div className="d-flex ">
                      <img src="/assets/images/Vector.png" alt="" />
                      <p>Email Support</p>
                    </div>
                    <div className="d-flex ">
                      <img src="/assets/images/tdesign_multiply.png" alt="" />
                      <p>Lifetime Access</p>
                    </div>
                    <div className="d-flex ">
                      <img src="/assets/images/tdesign_multiply.png" alt="" />
                      <p>Free Lifetime Updates</p>
                    </div>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Pricing;
